import React from "react"
import { graphql } from "gatsby"
import Notice from "../../components/notice"
import Navbar from "../../components/navbar"
import CallToAction from "../../components/callToAction"
import Footer from "../../components/footer"
import BlogFeatured from "../../components/BlogFeatured"
import BlogHeader from "../../components/BlogHeader"
import BlogMostRecent from "../../components/BlogMostRecent"
import Seo from "../../components/seo"

const BlogTag = ({ data, pageContext }) => {
  const tagName = pageContext.tag



  const NoticeData = data?.notice?.edges?.[0]?.node
  const NavbarData = data?.navbar?.edges?.[1]?.node
  const CalltoActionData = data?.callToAction?.edges?.[0]?.node
  const footerData = data?.footerColumn?.edges?.[1]?.node
  const blogData = data.posts.edges
  const featuredPosts = data.featured.edges[0]?.node

  const title = featuredPosts?.articleTitle
  const metaDescription = featuredPosts?.synopsis
  const imageUrl = featuredPosts?.articleBannerImage?.file?.url
  const url = typeof window !== 'undefined' ? window.location.href : '';

  const tagsPosts = data.tags.edges
  const tags = []
  tagsPosts.forEach(({ node: post }) => {
    !tags.includes(post.tags[0]) && tags.push(post.tags[0])
  })


  const relatedPosts = []
  blogData.forEach(({ node: blog }) => {
    !relatedPosts.includes(blog) && relatedPosts.push(blog)
  })
  return (
    <>
      <Seo
        title={title}
        metaDescription={metaDescription}
        imageUrl={imageUrl}
        url={url}
      />
      {NoticeData && <Notice NoticeData={NoticeData} />}
      {NavbarData && <Navbar NavbarData={NavbarData} />}
      {/* feature */}
      <BlogFeatured featuredPosts={featuredPosts} />

      {/* tag and search */}
      <BlogHeader tags={tags} tagName={tagName} />
      <BlogMostRecent
        data={relatedPosts}
        renderPagination={true}
        limit={6}
        tagName={tagName}
      />

      {CalltoActionData && <CallToAction CalltoActionData={CalltoActionData} />}
      <Footer variant="dark" footerData={footerData?.footerColumns} atlasOneLogo={footerData?.atlasOneLogo} />
    </>
  )
}

export default BlogTag

export const query = graphql`
  query BlogTagsQuery($tag: String!) {
    posts: allContentfulArticle(sort : { fields: [createdAt], order:DESC }, filter: {  tags : { eq: $tag } }) {
      edges {
        node {
            articleBannerImage{
              file{
                url
              }
            }
            articleTitle
            tags
            featured
            popular
            synopsis
            updatedAt
        }
      }
    }
    featured: allContentfulArticle(filter: {  featured : { eq: true } }) {
      edges {
        node {
            articleBannerImage{
              file{
                url
              }
            }
            articleTitle
            tags
            featured
            popular
            synopsis
            updatedAt
        }
      }
    }
    tags: allContentfulArticle{
                edges{
                    node{
                        tags
                    }
                }
    }
    notice: allContentfulNotice {
      edges {
        node {
          elementType
          theme
          noticeTitle {
            raw
          }
          tagline
          actionUrl
          noticeActionText
        }
      }
    }

    navbar: allContentfulHeader {
      edges {
        node {
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
          headerButtons {
            ... on ContentfulPrimaryButton {
              elementType
              id
              theme
              title
              url
            }
            ... on ContentfulSecondaryButton {
              elementType
              id
              theme
              title
              url
            }
          }
          headerMenuItems {
            id
            title
            headerMenuDropdown {
              items {
                description
                icon {
                  file {
                    url
                  }
                  title
                }
                title
                url
                page {
                  slug
                  title
                }
              }
            }
          }
        }
      }
    }

    callToAction: allContentfulCallToAction {
      edges {
        node {
          elementType
          theme
          title
          desc:description
          buttons {
            ... on ContentfulSecondaryButton {
              elementType
              theme
              title
              url
            }
            ... on ContentfulPrimaryButton {
              elementType
              theme
              title
              url
            }
          }
        }
      }
    }

    footerColumn: allContentfulFooter {
      edges {
        node {
          elementType
          footerColumns {
            columnTitle
            links {
              title
              description
              icon {
                file {
                  url
                }
              }
              url
              page {
                slug
                title
              }
            }
          }
          atlasOneLogo {
            image {
              file {
                url
              }
              title
            }
          }
        }
      }
    }
    allContentfulPage {
      edges{
        node{
          slug
          title
          metaTitle
          metaDescription
          ogImage{
              file{
                  url
              }
          }
          sections{
            ... on ContentfulNotice {
              elementType
              theme
              noticeTitle {
                  raw
              }
              tagline
              actionUrl
              noticeActionText
          }
            ... on ContentfulHeader {
              headerMenuItems {
                title
                headerMenuDropdown {
                  items {
                    description
                    icon {
                      file {
                        url
                      }
                      title
                    }
                    title
                    url
                    page {
                      slug
                      title
                    }
                  }
                }
              }
              headerButtons {
                ... on ContentfulPrimaryButton {
                  elementType
                  id
                  theme
                  title
                  url 
                }
                ... on ContentfulSecondaryButton {
                  elementType
                  id
                  theme
                  title
                  url 
                }
              }
              atlasOneLogo {
                image {
                  file {
                    url
                  }
                  title
                }
              }
            }
            ... on ContentfulCallToAction {
              elementType
              theme
              title
              buttons {
                  ... on ContentfulSecondaryButton {
                      elementType
                      theme
                      title
                      url
                  }
                  ... on ContentfulPrimaryButton {
                      elementType
                      theme
                      title
                      url
                  }
              }
            }
            ... on ContentfulFooter {
              elementType
              ... on Node {
                ... on ContentfulFooterColumn {
                  columnTitle
                  links {
                      title
                      description
                      icon {
                          file {
                              url
                          }
                      }
                      url
                      page {
                        slug
                        title
                      }
                  }
                }
              }
              atlasOneLogo {
                  image {
                      file {
                          url
                      }
                      title
                  }
              }
            }

          }
        }
      }
    }
  }
`


