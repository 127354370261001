import React from "react"
import { Link } from "gatsby"

const BlogFeatured = ({ featuredPosts, backlink }) => {
	return (
		<div className="w-screen relative blog-gradient">
			<div className="absolute top-0 h-full -z-20">
				<img
					src={featuredPosts.articleBannerImage.file.url}
					alt=""
					className="object-cover w-screen h-full"
				/>
			</div>
			<div className="px-5 md:px-10 xl1:px-40  2xl:px-[70px] md:-mt-[118px]">
				<div className="flex items-center justify-start max-w-screen-xl mx-auto min-h-[500px] md:min-h-[630px]">
					<div className={`w-full items-center md:w-3/5 p-0 ${backlink === true ? " md:mt-[189px]" : "md:mt-[209px]"}`}>
						<p className={`text-base Figtree-SemiBold text-blue uppercase font-semibold leading-4 tracking-widest`}>{featuredPosts.tags[0]}</p>
						<h1 className="pt-[10px] text-[28px] md:text-[44px] font-normal text-ProjectBlack Saira-Bold leading-lineheight120">{featuredPosts.articleTitle}</h1>
						<p className="pt-[10px] font-workSans font-normal md:text-[19px] text-ProjectBlack leading-6">{featuredPosts.synopsis}</p>
						{!backlink && (
							<div className="mt-[30px] flex items-center md:mb-[91px]">
								<Link
									to={`/blog/${featuredPosts.articleTitle.replace(/\s+/g, '-').toLowerCase()}`}
									className="flex items-center justify-center h-[3.125rem] font-normal text-center uppercase border-2 rounded-sm Saira-Bold min-w-[140px] md:min-w-[150px] text-xs md:text-sm tracking-atswide leading-lineheight120
																							bg-PrimaryBlue border-ProjectBlue text-TextPrimaryWhite hover:bg-PrimaryHoverBlue hover:border-PrimaryHoverBlue duration-300 ease-in-out"
									role="presentation"
								>Read Article
								</Link>
							</div>
						)}
						{backlink &&
							(
								<div className="flex items-center rounded mt-[40px] md:mb-[71px]">
									<img srcset="//images.ctfassets.net/tafzvdx4ctpz/2JqK5JiGZescLVHlH5fq6R/da074fcea2bb448f33deb44c9de9eae9/iTunesArtwork.png?w=200 1x, 
															 //images.ctfassets.net/tafzvdx4ctpz/2JqK5JiGZescLVHlH5fq6R/da074fcea2bb448f33deb44c9de9eae9/iTunesArtwork.png?w=300 1.5x, 
															 //images.ctfassets.net/tafzvdx4ctpz/2JqK5JiGZescLVHlH5fq6R/da074fcea2bb448f33deb44c9de9eae9/iTunesArtwork.png?w=400 2x, 
															 //images.ctfassets.net/tafzvdx4ctpz/2JqK5JiGZescLVHlH5fq6R/da074fcea2bb448f33deb44c9de9eae9/iTunesArtwork.png?w=600 3x, " 
										className="w-[64px] h-[64px] rounded-full" 
										alt=""
									/>
									<div className="ml-[21px]">
										<p className="text-TextPrimaryWhite font-workSans font-bold text-[19px] leading-6">Atlas One</p>
										<p className="text-TextPrimaryWhite font-workSans font-normal text-base leading-6">Customer Success Team</p>
									</div>
								</div>
							)

						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default BlogFeatured
