
import React from "react"
import { Link } from "gatsby"

const BlogHeader = ({ tags, tagName }) => {
	return (
		<>
			<div className="w-screen bg-black px-5 md:px-10 xl1:px-40">
				<div className="max-w-screen-xl mx-auto">
					<div className="flex  flex-col lg:flex-row items-start lg:items-center">
						<div className="flex-1 items-center ">
							<ul className="items-center">
								{tags && tags.map((tag, index) => (
									<li
										className={`w-[150px] md:w-auto md:py-[47px] font-semibold text-sm md:text-base uppercase cursor-pointer Figtree-SemiBold group navbar-border-bottom mx-[5px] md:mx-[10px] navbar-item-anim tracking-widest  duration-300 ease-animationAts delay-0   
																			text-ProjectBlack `}
									>
										<Link to={`/category/${tag.toLowerCase().replace(" ", "-")}`} className={`${tagName && tag.toLowerCase() === tagName.toLowerCase() ? " text-ProjectBlue" : " text-TextPrimaryWhite"}`}>{tag}</Link>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
export default BlogHeader
